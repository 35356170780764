import React from 'react';
import { Redirect } from 'react-router-dom';
import dashboardRoutes from './views/dashboard/dashboardRoutes';
import staffDashboardRoutes from './views/staff-dashboard/dashboardRoutes';
import AuthGuard from './auth/AuthGuard';
import pagesRoutes from './views/pages/pagesRoutes';
import contactRoutes from './views/app/contact/contactRoutes';
import sessionsRoutes from './views/sessions/sessionsRoutes';
import notificationRoutes from './views/notification/notificationRoutes';
import staffNotificationRoutes from './views/staff-notification/notificationRoutes';
import organizationRoutes from './views/organization/organizationRoutes';
import interviewRoutes from './views/interview/interviewRoutes';
import employeesRoutes from './views/employees/employeesRoutes';
import roomBookingRoutes from './views/room-booking/roomBookingRoutes';
import staffroomBookingRoutes from './views/staff-room-booking/roomBookingRoutes';
import chatRoutes from './views/chat/chatRoutes';
import attendanceRoutes from './views/attendance/attendanceRoutes';
import staffAttendanceRoutes from './views/staff-attendance/attendanceRoutes';
import assetRoutes from './views/asset/assetRoutes';
import payrollRoutes from './views/payroll/payrollRoutes';
import staffPaySlipRoutes from './views/staff-payslip/paySlipRoutes';
import claimsRoutes from './views/claims/claimsRoot';
import staffClaims from './views/staff-claims/claimsRoutes';
import leavesRoutes from './views/leaves/leavesRoutes';
import staffLeavesRoutes from './views/staff-leaves/leavesRoutes';
import deskBookingRoutes from './views/desk-booking/deskBookingRoutes';
import staffDeskBookingRoutes from './views/staff-deskbooking/deskBookingRoutes';
import appraisalRoutes from './views/appraisal/appraisalRoot';
import staffAppraisalRoutes from './views/staffAppraisal/appraisalRoot';
import shiftPlanRoutes from './views/shift-planning/shiftPlaningRoutes';
import staffShiftScheduleRoutes from './views/staff-schedule/shiftPlaningRoutes';
import superDashboardRoutes from './views/super-admin/dashboard/notiRoot';
import superNotificationRoutes from './views/super-admin/notification/notiRoot';
import superMarketingRoutes from './views/super-admin/marketing/marketingRoot';
import superManageSuperAdminRoutes from './views/super-admin/manage-super-admin/manageSaRoot';
import superManageEntitiesRoutes from './views/super-admin/manage-entities/manageEntitiesRoot';
import superManageAdminRoutes from './views/super-admin/manage-admin/manageAdminRoot';
import superPlansRoutes from './views/super-admin/plans/managePlanRoot';
import SASessionsRoutes from './views/super-admin/session/SASessionsRoutes';
import financeRoutes from './views/supper-admin/finance/financeRoot';
import supportRoutes from './views/support/supportRoot';

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionsRoutes,
  ...SASessionsRoutes,
  {
    path: '/',
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...staffDashboardRoutes,
      ...pagesRoutes,
      ...contactRoutes,
      ...leavesRoutes,
      ...staffLeavesRoutes,
      ...deskBookingRoutes,
      ...staffDeskBookingRoutes,
      ...notificationRoutes,
      ...staffNotificationRoutes,
      ...organizationRoutes,
      ...interviewRoutes,
      ...employeesRoutes,
      ...assetRoutes,
      ...payrollRoutes,
      ...staffPaySlipRoutes,
      ...roomBookingRoutes,
      ...staffroomBookingRoutes,
      ...chatRoutes,
      ...attendanceRoutes,
      ...staffAttendanceRoutes,
      ...claimsRoutes,
      ...staffClaims,
      ...shiftPlanRoutes,
      ...staffShiftScheduleRoutes,
      ...redirectRoute,
      ...appraisalRoutes,
      ...staffAppraisalRoutes,
      ...superDashboardRoutes,
      ...superNotificationRoutes,
      ...superMarketingRoutes,
      ...superManageSuperAdminRoutes,
      ...superManageEntitiesRoutes,
      ...superManageAdminRoutes,
      ...superPlansRoutes,
      ...financeRoutes,
      ...supportRoutes,
      ...errorRoute,
    ],
  },
];

export default routes;
